import React from "react"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import Header from "../components/header/header"
import Form from "../components/form/form"

const handleSubmit = (event) => {
  event.preventDefault();
  const data = new FormData(event.target);

  //prevents spam
  if (event.target.sc.value!=='') {
    return;
  }

  fetch('https://script.google.com/macros/s/AKfycbwc0Bh6oqn5zr_6ZbNytUInZHbLJjrHQZTcVLZs14qjTYh7sjDQ/exec', {
    method: 'POST',
    body: data,
  });
}

const ContactPage = () => (
  <Layout>
  <SEO title="QueroPedir - Fale Conosco" description="Neste espaço você pode esclarecer suas dúvidas, dar sua opinião ou sugestão e falar com a gente sobre outros assuntos que desejar. " keywords={[`contato`, `fale conosco`]} />
    <Header siteTitle="QueroPedir">
      <h1>Fale Conosco</h1>
    </Header>
    <div className="container become-partner">
      <div className="row text-center">
        <h4>Neste espaço você pode esclarecer suas dúvidas, dar sua opinião ou sugestão<br className="d-none d-md-block"/>e falar com a gente sobre outros assuntos que desejar <strong>=)</strong></h4>
      </div>
      <div className="row">
        <div className="col-md-2"></div>
        <div className="col-md">
          <Form formName="Contato">
            <input name="Nome" type="text" placeholder="Nome" className="form-control" required/>
            <select name="Assunto" className="form-control" required>
              <option value="" disabled selected hidden>Assunto</option>
              <option value="Elogios">Elogios</option>
              <option value="Sugestões">Sugestões</option>
              <option value="Reclamações">Reclamações</option>
              <option value="Problemas com Pedido">Problemas com um Pedido</option>
              <option value="Problemas com o Aplicativo">Problemas no Aplicativo</option>
              <option value="Outros">Outros</option>
            </select>
            <input name="Email"  type="email" placeholder="E-mail" className="form-control" required/>
            <input name="Telefone" type="text" placeholder="Telefone" className="form-control" required/>
            <textarea name="Mensagem"  type="text" placeholder="Mensagem" className="form-control" rows="5" required/>
          </Form>
        </div>
        <div className="col-md-2"></div>
      </div>
    </div>
  </Layout>
)

export default ContactPage
